import React from "react"
import { graphql, Link } from "gatsby"
import { LocaleContext } from "../../components/layout"
import SEO from "../../components/seo"

function Subcategory({ data: { prismicSubcategory } }) {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if (lang.i18n[lang.locale] != undefined) {
    i18n = lang.i18n[lang.locale]
  }
  return (
    <>
    <SEO title={prismicSubcategory.data.subcategory_name} keywords={[`navigo`, `garmin`]} />
      <div className="container subcategory-page-container">
        {prismicSubcategory.data.category &&
          prismicSubcategory.data.category.document.map(category => (
            <div className="row">
              <div className="col-sm-4 d-none d-md-block">
                <table className="category-table">
                  <th className="heading">{category.data.category_name}</th>
                  {category &&
                    category.data.subcategories.map(
                      x =>
                        x.subcategory &&
                        x.subcategory.document.map(item => (
                          <tr className="item">
                            <Link to={item.uid}>
                              {item.data.subcategory_name}{" "}
                            </Link>
                          </tr>
                        ))
                    )}
                </table>
              </div>
              <div className="col-xs-12 col-sm-8 text-center">
                <div className="row">
                  {prismicSubcategory &&
                    prismicSubcategory.data.products.length > 0 &&
                    prismicSubcategory.data.products.map(
                      y =>
                        y.product &&
                        y.product.document.map(product => (
                          <div className="col-xs-12 col-sm-6">
                            <div className="product-box">
                              <div className="product-image">
                                <img
                                  className="img-responsive"
                                  src={product.data.thumbnail_image.url}
                                />
                              </div>
                              <div className="product-name">
                                {product.data.product_name}
                              </div>
                              <div className="product-price m-b-10 m-t-10">
                                {product.data.product_price}
                              </div>
                              <a href={product.uid}>
                                <div className="product-details">
                                  {i18n.details}
                                </div>
                              </a>
                            </div>
                          </div>
                        ))
                    )}
                  {prismicSubcategory &&
                    prismicSubcategory.data.products.length <= 0 &&
                    prismicSubcategory.data.order_directly_message && (
                      <div
                        style={{
                          minHeight: "400px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            prismicSubcategory.data.order_directly_message.html,
                        }}
                      />
                    )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export default Subcategory

export const pageQuery = graphql`
  query SubcategoryBySlug($uid: String!, $locale: String!) {
    prismicSubcategory(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        subcategory_name
        category {
          document {
            data {
              category_name
              subcategories {
                subcategory {
                  document {
                    uid
                    data {
                      subcategory_name
                    }
                  }
                }
              }
            }
          }
        }
        products {
          product {
            document {
              uid
              data {
                product_name
                product_price
                thumbnail_image {
                  url
                }
              }
            }
          }
        }
        order_directly_message {
          html
        }
      }
    }
  }
`
